// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
	$(document).on('click', '.navopen', function () {
		$('.nav').addClass('is-active');
	})
	$(document).on('click', '.navclose', function () {
		$('.nav').removeClass('is-active');
	})
	$(document).on('click', '.nav .anker', function () {
		$('.nav').removeClass('is-active');
	});
})
